import { useModalState } from './Context';
import { Modal } from './Modal';

export function ModalManager(): import('react').ReactElement {
  const { modals } = useModalState();
  return (
    <>
      {modals.map((item) => (
        <Modal key={item.id} {...item}>
          {item.content}
        </Modal>
      ))}
    </>
  );
}
