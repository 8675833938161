/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useEffect } from 'react';
import { cx } from '@linaria/core';
import { Dialog as ReakitDialog, DialogBackdrop, DialogHTMLProps } from 'reakit/Dialog';
import type { DialogStateReturn } from 'reakit/Dialog';
import { flex } from 'styles/utils/core';
import { noop } from 'utils/fp';
import { Button } from '../deprecated/Button/Button';
import { backdropStyles, dialogStyles } from './Dialog_styles';
import { ScrollWrapper } from './ScrollWrapper';

export interface DialogProps extends DialogHTMLProps {
  backdropClassName?: string;
  contentOuterWrapperClassName?: string;
  contentWrapperClassName?: string;
  title: string;
  showBackdrop?: boolean;
  closeOnBackdropClick?: boolean;
  hideOnEsc?: boolean;
  makeActions?: (closeModal: () => void) => void;
  onClose: () => void;
  dialog: DialogStateReturn;
  scrollOnOverflow?: boolean;
}

export const Dialog: import('react').FC<DialogProps> = ({
  children,
  className,
  backdropClassName,
  contentOuterWrapperClassName,
  contentWrapperClassName,
  title,
  showBackdrop = true,
  closeOnBackdropClick = false,
  hideOnEsc = false,
  onClose,
  makeActions = defaultActionMaker,
  dialog,
  scrollOnOverflow = true,
  ...rest
}) => {
  const closeModal = (): void => {
    onClose();
    dialog.hide();
  };

  const dialogHandler: React.MouseEventHandler<HTMLDivElement> = (evt) => {
    if ((evt.target as Element).classList.contains('reakit-dialog-backdrop')) {
      onClose();
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (dialog.visible) {
      ref.current?.focus();
    }
  }, [dialog.visible]);

  if (showBackdrop) {
    return (
      <DialogBackdrop
        {...dialog}
        data-testid="dialogBackdrop"
        className={cx(backdropStyles, 'reakit-dialog-backdrop', backdropClassName)}
        // don't change to onClick as it causes bugs if modal height jumps due to tabs etc
        onMouseDown={closeOnBackdropClick ? dialogHandler : noop}
      >
        <ReakitDialog
          hideOnClickOutside={closeOnBackdropClick}
          hideOnEsc={hideOnEsc}
          className={cx(dialogStyles, 'reakit-dialog', className)}
          {...dialog}
          aria-label={title}
          ref={ref}
          {...rest}
        >
          {title && title.length ? <h2>{title}</h2> : null}
          <div className={cx(flex.init, flex.direction.column, flex.justifyContent.between, 'fullHeight', contentOuterWrapperClassName)}>
            {scrollOnOverflow ? <ScrollWrapper className={contentWrapperClassName}>{children}</ScrollWrapper> : children}
            {makeActions(closeModal)}
          </div>
        </ReakitDialog>
      </DialogBackdrop>
    );
  }

  return (
    <ReakitDialog {...dialog} hideOnClickOutside={closeOnBackdropClick} className={cx(dialogStyles, 'dialog')} aria-label={title} ref={ref} {...rest}>
      {title}
      {children}
      {makeActions(onClose)}
    </ReakitDialog>
  );
};

export { useDialogState } from 'reakit/Dialog';

function defaultActionMaker(closeModal: () => void): import('react').ReactElement {
  return (
    <Button data-testid="dialogClose" onClick={closeModal}>
      Close
    </Button>
  );
}
