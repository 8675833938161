import { Dialog, useDialogState } from 'components/Dialog';
import { useCallback, useEffect, useRef } from 'react';
import { breakpoints } from 'styles/utils';
import { useRouter } from 'next/router';
import type { DialogInitialState } from 'reakit';
import { noop } from 'utils/fp';
import { css, cx, LinariaClassName } from '@linaria/core';
import { useModalDispatch, ModalActionTypes, ModalActions } from '../Context';
export interface ModalProps {
  title?: string;
  className?: LinariaClassName | string;
  backdropClassName?: LinariaClassName | string;
  contentOuterWrapperClassName?: LinariaClassName | string;
  contentWrapperClassName?: LinariaClassName | string;
  id: string;
  showBackdrop?: boolean;
  closeOnBackdropClick?: boolean;
  hideOnEsc?: boolean;
  makeActions?: (closeModal: () => void) => void;
  initialState?: DialogInitialState;
  onClose?: () => void;
  closed?: boolean;
  disableRouter?: boolean;
  scrollOnOverflow?: boolean;
}
const makeCloseHandler = (dispatch: import('react').Dispatch<ModalActions>, id: string, onClose: ModalProps['onClose'] = noop) => (): void => {
  dispatch({
    type: ModalActionTypes.Close,
    payload: {
      id
    }
  });
  onClose();
};
export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  backdropClassName,
  contentOuterWrapperClassName,
  contentWrapperClassName,
  title,
  id,
  showBackdrop,
  closeOnBackdropClick,
  hideOnEsc,
  initialState,
  makeActions,
  onClose,
  closed,
  scrollOnOverflow
}) => {
  const dispatch = useModalDispatch();
  const dialog = useDialogState(initialState);
  const router = useRouter();
  const counter = useRef(0);
  const closeHandler = useCallback(() => {
    makeCloseHandler(dispatch, id, onClose);
    // remove modalId from query params when modal is closed
    if (router.isReady) {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query
        }
      }, undefined, {
        shallow: true
      });
    }
  }, [dispatch, id, onClose]);
  useEffect(() => {
    if (closed) {
      dialog.hide();
    }
  }, [closed]);
  useEffect(() => {
    if (dialog.visible) {
      return;
    }

    // WE HAVE TO DO EXTRA RERENDER TO WAIT FOR ANIMATION TO FINISH
    counter.current += 1;
    if (!dialog.animated || counter.current === 2) {
      dispatch({
        type: ModalActionTypes.Destroy,
        payload: {
          id
        }
      });
    }
  }, [dispatch, dialog.visible, dialog.animating, dialog.animated]);
  return <Dialog className={cx(modalStyle, className)} backdropClassName={backdropClassName} contentOuterWrapperClassName={contentOuterWrapperClassName} contentWrapperClassName={contentWrapperClassName} title={title || ''} aria-label={title || `Modal ${id}`} data-testid={`modal-${title}-${id}`} makeActions={makeActions} onClose={closeHandler} showBackdrop={showBackdrop} closeOnBackdropClick={closeOnBackdropClick} hideOnEsc={hideOnEsc} dialog={dialog} scrollOnOverflow={scrollOnOverflow}>
      {children}
    </Dialog>;
};
Modal.defaultProps = {
  showBackdrop: true,
  initialState: {
    visible: true,
    animated: true
  }
};
export const testable = {
  makeCloseHandler
};
const modalStyle = "omponents__ModalManager__Modal__Modal_tsx-modalStyle-m1b1yxnj";

require("./Modal.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Modal.tsx");